.navbar {
	position: fixed;
	box-sizing: border-box;
	z-index: 1;
	margin: 0;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 1rem;
	padding-left: 2rem;
	padding-right: 2rem;
	background: transparent;
	color: var(--color-white);
	position: absolute;
	width: 100%;
}

nav a {
	margin: 0 1rem;
	color: var(--color-white);
	text-decoration: none;
}

nav a:hover {
	color: var(--color-primary);
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--color-bg);
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}



	nav a {
		font-size: 2rem;
	}
}