.nav {
  position: fixed;
  box-sizing: border-box;
  z-index: 1;
  margin: 0;
}

:root {
  --bar-width: 60px;
  --bar-height: 8px;
  --hamburger-gap: 6px;
  --foreground: var(--color-bg-variant);
  --background: var(--color-primary);
  --hamburger-margin: 8px;
  --animation-timing: 250ms ease-in-out;
  --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
}

.hamburger-menu {
  --x-width: calc(var(--hamburger-height) * 1.41421356237);

  display: flex;
  flex-direction: column;
  gap: var(--hamburger-gap);
  width: max-content;
  position: absolute;
  top: var(--hamburger-margin);
  left: var(--hamburger-margin);
  z-index: 2;
  cursor: pointer;
}

.hamburger-menu:has(input:checked) {
  --foreground: var(--color-white);
  --background: var(--color-bg-variant);
}

/* .hamburger-menu:has(input:focus-visible)::before,
.hamburger-menu:has(input:focus-visible)::after,
.hamburger-menu input:focus-visible {
  border: 1px solid var(--background);
  box-shadow: 0 0 0 1px var(--foreground);
} */

.hamburger-menu::before,
.hamburger-menu::after,
.hamburger-menu input {
  content: "";
  width: var(--bar-width);
  height: var(--bar-height);
  background-color: var(--foreground);
  border-radius: 9999px;
  transform-origin: left center;
  transition: opacity var(--animation-timing), width var(--animation-timing),
    rotate var(--animation-timing), translate var(--animation-timing),
    background-color var(--animation-timing);
}

.hamburger-menu input {
  appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
  pointer-events: none;
}

.hamburger-menu:has(input:checked)::before {
  rotate: 45deg;
  width: var(--x-width);
  translate: 0 calc(var(--bar-height) / -2);
}

.hamburger-menu:has(input:checked)::after {
  rotate: -45deg;
  width: var(--x-width);
  translate: 0 calc(var(--bar-height) / 2);
}

.hamburger-menu input:checked {
  opacity: 0;
  width: 0;
}

.sidebar {
  transition: translate var(--animation-timing);
  translate: -100%;
  padding: 0.5rem 1rem;
  padding-top: calc(var(--hamburger-height) + var(--hamburger-margin) + 1rem);
  background-color: var(--foreground);
  color: var(--background);
  min-height: 100vh;
  width: 10rem;
}

.sidebar a {
  display: block;
  color: var(--color-white);
}

.sidebar a:hover {
  color: var(--color-primary);
}

.hamburger-menu:has(input:checked) + .sidebar {
  translate: 0;
}