*, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
  
.portfolio__container {
    display: flex;
    flex-direction: column;
    position: relative;
    background: var(--color-bg);
    align-items: center;
}
  
.portfolio__bloc-tabs {
  display: flex;
}

.portfolio__tabs:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.portfolio__tabs {
  padding: 1rem 2rem;
    text-align: center;
    width: 150px;
    background: var(--color-bg);
    color: var(--color-primary);
    cursor: pointer;
    position: relative;
    outline: none;
    border: 2px solid var(--color-primary);
    border-radius: 2rem;
    margin-bottom: 3rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.portfolio__active-tabs  {
    background: var(--color-primary);
    border: 2px solid transparent;
    color: var(--color-bg);
}


button {
    border: none;
    font-weight: 500;
    font-family: "Poppins";
    color: var(--color-white);
}

.portfolio__content-tabs {
    flex-grow : 1;
}

.portfolio__content {
    background: var(--color-bg);
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
}

.portfolio__content p {   
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
    text-align: center;
}

.portfolio__active-content {
    display: block;
}

  /* Di einzelne Boxe mit dä Projekt drin */
  .portfolio__projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* ===== MEDIA QUERIES (MEDIUM DEVICES) ===== */
@media screen and (max-width: 1024px) {
    .portfolio__projects {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

    .portfolio__tabs {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

}

/* ===== MEDIA QUERIES (SMALL DEVICES) ===== */
@media screen and (max-width: 600px) {
    .portfolio__projects {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .portfolio__tabs {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
}